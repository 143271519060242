import Vue from 'vue'
import Router from '@base/lib/commonRouter'
import { fetchUserInfoFromLocal } from './lib/utils'
import JagoRoute from '@base/router/jago'
import BaseCommonRoute from '@base/router/common'
import MeRoute from '@base/router/me'
import EffortlessLoanRoute from '@el/router/effortless-loan'
import { formatRouteList, removeLoading } from '@base/lib/utils'
import AccountSecurityRoute from '@base/router/security'
import DeleteAccountRoute from '@base/router/deleteAccount'
import DBRAlert from '@base/router/dbrAlert'
import VatRoute from '@base/router/vat'

// faq
const LoginFaq = () => import(/* webpackChunkName: "LoginFaq" */ './views/login-faq/index.vue')
const NoSmsCode = () => import(/* webpackChunkName: "LoginFaq" */ './views/login-faq/NoSmsCode.vue')
const NoPhoneNumber = () => import(/* webpackChunkName: "LoginFaq" */ './views/login-faq/NoPhoneNumber.vue')

// apply
const EntryPage = () => import(/* webpackChunkName: "entryPage" */ './views/apply/Entry.vue')
const Apply = () => import(/* webpackChunkName: "apply" */ './views/apply/index.vue')
const Ktp = () => import(/* webpackChunkName: "ktp" */ './views/apply/step/Ktp.vue')
const KtpHolding = () => import(/* webpackChunkName: "ktpHolding" */ './views/apply/step/KtpHolding.vue')
const BankInfo = () => import(/* webpackChunkName: "bankInfo" */ './views/apply/step/BankInfo.vue')
const First = () => import(/* webpackChunkName: "first" */ './views/apply/step/StepFirst.vue')
const Second = () => import(/* webpackChunkName: "second" */ './views/apply/step/StepSecond.vue')
const ApplySupplement = () => import(/* webpackChunkName: "applySupplement" */ './views/apply/step/Supplement.vue')

// update info
const UpdateInfo = () => import(/* webpackChunkName: "PersonalInfoUpdate" */ './views/update-info/index.vue')

// A/B Test: Linear_Application_Form B
const LinearApply = () => import(/* webpackChunkName: "linearApply" */ './views/apply/step/linear-apply-b/index.vue')
const LinearPersonalInfo = () =>
  import(/* webpackChunkName: "linearPersonalInfo" */ './views/apply/step/PersonalInfo.vue')
const LinearWorkInfo = () => import(/* webpackChunkName: "linearWorkInfo" */ './views/apply/step/WorkInfo.vue')
const LinearContactInfo = () => import(/* webpackChunkName: "linearContactInfo" */ './views/apply/step/ContactInfo.vue')
const LinearBankInfo = () => import(/* webpackChunkName: "linearBankInfo" */ './views/apply/step/BankInfo.vue')
const LinearSupplement = () => import(/* webpackChunkName: "linearSupplement" */ './views/apply/step/Supplement.vue')

const NativeKTPEntrance = () =>
  import(/* webpackChunkName: "nativeKTPEntrance" */ './views/apply/step/NativeKTPEntrance.vue')
const PdlPreBorrow = () =>
  import(/* webpackChunkName: "pdlPreBorrow" */ '@base/views/apply/step/pre-borrow/PdlPreBorrow.vue')
const SelectLoan = () => import(/* webpackChunkName: "SelectLoan" */ './views/apply/step/pre-borrow/SelectLoan.vue')

// ABTest
const MandatoryBranch = () =>
  import(/* webpackChunkName: "MandatoryBranch" */ './views/apply/step/pre-borrow/MandatoryBranch.vue')

const ModuleApply = () => import(/* webpackChunkName: "moduleApply" */ './views/apply/StepList.vue')
const ModulePersonalInfo = () => import(/* webpackChunkName: "moduleBasicInfo" */ './views/apply/step/PersonalInfo.vue')
const ModuleWorkInfo = () => import(/* webpackChunkName: "moduleWorkInfo" */ './views/apply/step/WorkInfo.vue')
const ModuleContactInfo = () => import(/* webpackChunkName: "moduleContactInfo" */ './views/apply/step/ContactInfo.vue')

// resubmit
const Resubmit = () => import(/* webpackChunkName: "resubmit" */ './views/resubmit/index.vue')
const ResubmitFaceDetectionStart = () =>
  import(/* webpackChunkName: "resubmitFace" */ './components/face-detection/FaceDetecStart.vue')
const ResubmitFaceDetectionNext = () =>
  import(/* webpackChunkName: "resubmitFace" */ './components/face-detection/FaceDetectionNext.vue')
const ResubmitKtp = () => import(/* webpackChunkName: "resubmitKtp" */ './views/resubmit/step/Ktp.vue')
const ResubmitKtpHolding = () =>
  import(/* webpackChunkName: "resubmitKtpHolding" */ './views/resubmit/step/KtpHolding.vue')
const ResubmitEmailVerification = () =>
  import(/* webpackChunkName: "ResubmitEmail" */ './views/resubmit/step/Email.vue')
const ResubmitBankInfo = () => import(/* webpackChunkName: "resubmitBankInfo" */ './views/resubmit/step/BankInfo.vue')

const CommonDownloadApp = () => import(/* webpackChunkName: "download" */ '@base/components/CommonDownloadApp.vue')

// home
const ApplyLoan = () => import(/* webpackChunkName: "loan" */ '@base/views/apply-loan/index.vue')
const BankList = () => import(/* webpackChunkName: "loan" */ './views/add-bank-card/BankList.vue')
const BankFaceDetectionStart = () =>
  import(/* webpackChunkName: "loan" */ './components/face-detection/FaceDetecStart.vue')
const BankFaceDetectionNext = () =>
  import(/* webpackChunkName: "loan" */ './components/face-detection/FaceDetectionNext.vue')
const AddLoanBankCard = () => import(/* webpackChunkName: "loan" */ './views/add-bank-card/AddBankCard.vue')
const TCAgreement = () => import(/* webpackChunkName: "loan" */ '@base/views/apply-loan/common/T&CAgreement.vue')
const AgreementIframe = () => import(/* webpackChunkName: "loan" */ './views/service-document/AgreementInIframe.vue')
const ViewPdf = () => import('@base/views/apply-loan/common/ViewPdf.vue')

// bill
const PaymentMethod = () => import(/* webpackChunkName: "paymentMethod" */ './views/payment-method/index.vue')
const BillHowToPay = () => import(/* webpackChunkName: "billHowToPay" */ './views/payment-method/HowToPay.vue')
const ApplyExtension = () =>
  import(/* webpackChunkName: "applyExtension" */ './views/payment-method/ApplyExtension.vue')
const BillStatementsV2 = () => import(/* webpackChunkName: "BillStatementsV2" */ '@base/views/bill/BillStatements.vue')
const StatementDetails = () =>
  import(/* webpackChunkName: "StatementDetails" */ '@base/views/bill/StatementDetails.vue')

// me
const HelpCenter = () => import(/* webpackChunkName: "helpCenter" */ './views/help-center/index.vue')
const HelpCenterHowToPay = () => import(/* webpackChunkName: "helpCenterHowToPay" */ './views/help-center/HowToPay.vue')
const MyAccount = () => import(/* webpackChunkName: "MyAccount" */ '@base/views/me/my-account/index.vue')
const MyProfile = () => import(/* webpackChunkName: "myProfile" */ './views/my-account/my-profile/index.vue')
const ChangeUserName = () =>
  import(/* webpackChunkName: "ChangeUserName" */ '@base/views/me/my-account/ChangeUserName.vue')
const MyPersonalInfo = () =>
  import(/* webpackChunkName: "myPersonalInfo" */ './views/my-account/my-profile/PersonalInfo.vue')
const MyContactInfo = () => import(/* webpackChunkName: "myContactInfo" */ './views/apply/step/ContactInfo.vue')
const MyResidentialInfo = () =>
  import(/* webpackChunkName: "myResidentialInfo" */ './views/my-account/my-profile/ResidentialInfo.vue')
const MyWorkInfo = () => import(/* webpackChunkName: "myWorkInfo" */ './views/apply/step/WorkInfo.vue')
const MyBankInfo = () => import(/* webpackChunkName: "myBankInfo" */ './views/apply/step/BankInfo.vue')
const MyLinkAccount = () =>
  import(/* webpackChunkName: "myLinkAccount" */ './views/my-account/my-link-account/index.vue')
const MyLoans = () => import(/* webpackChunkName: "MyLoans" */ '@base/views/me/my-loans/index.vue')
const LoanDetails = () => import(/* webpackChunkName: "LoanDetails" */ '@base/views/me/my-loans/LoanDetails.vue')
// invoice
const InvoicePage = () => import(/* webpackChunkName: "InvoicePage" */ '@base/views/loan-invoice/index.vue')
const InvoicePdf = () => import(/* webpackChunkName: "InvoicePage" */ '@base/views/loan-invoice/invoicePdf.vue')

// additional
// const Supplement = () => import(/* webpackChunkName: "supplement" */ './views/additionals/supplement/index.vue')
const SupplementUpload = () =>
  import(/* webpackChunkName: "supplementUpload" */ './views/additionals/supplement/SupplementUpload.vue')
const HRContactInfo = () =>
  import(/* webpackChunkName: "HRContactInfo" */ './views/additionals/supplement/HRContactInfo.vue')
const RepaymentRecord = () =>
  import(/* webpackChunkName: "repaymentRecord" */ './views/additionals/supplement/RepaymentRecord.vue')
const VerifyNik = () => import(/* webpackChunkName: "verifyNik" */ './views/additionals/verify-nik/index.vue')
const LinkAccountDetail = () =>
  import(/* webpackChunkName: "linkAccountDetail" */ './views/additionals/reward/LinkAccount.vue')
const VideoKyc = () => import(/* webpackChunkName: "VideoKyc" */ './views/video-kyc/index.vue')

//
const FiveStar = () => import(/* webpackChunkName: "fiveStar" */ './views/android-push/FiveStar.vue')
const TemporarySuccess = () =>
  import(/* webpackChunkName: "temporarySuccess" */ './views/android-push/TemporarySuccess.vue')
const VipProd = () => import(/* webpackChunkName: "vipProd" */ './views/android-push/VipProd.vue')

//ios
const EntryView = () => import(/* webpackChunkName: "entryView" */ '@base/views/apply/Entry.vue')
const ModuleLivenessIntroduce = () =>
  import(/* webpackChunkName: "linearLivenessIntroduce" */ '@base/views/apply/step/LivenessIntroduce.vue')
const LinearLivenessResult = () =>
  import(/* webpackChunkName: "LinearLivenessResult" */ '@base/views/apply/common/livenessResult.vue')
const RejectedPage = () => import(/* webpackChunkName: "RejectedPage" */ '@base/views/apply/common/rejectedPage.vue')

const wpsEntryPage = () => import(/* webpackChunkName: "wpsEntryPage" */ '@base/views/wps-insurance/entryPage.vue')
const ApplyInstallmentPage = () =>
  import(/* webpackChunkName: "ApplyInstallmentPage" */ '@base/views/bill/bill-installment/applyInstallment.vue')
const BillInstallmentFaqPage = () =>
  import(/* webpackChunkName: "ApplyInstallmentPage" */ '@base/views/bill/bill-installment/BillInstallmentFaq.vue')
const BillApplication = () =>
  import(/* webpackChunkName: "BillApplication" */ '@base/views/bill/bill-installment/billApplication.vue')
const BillProcess = () => import(/* webpackChunkName: "BillProcess" */ '@base/views/bill/bill-installment/process.vue')
const BillSchedule = () =>
  import(/* webpackChunkName: "BillSchedule" */ '@base/views/bill/bill-installment/schedule.vue')
const ReminderSuccess = () =>
  import(/* webpackChunkName: "ReminderSuccess" */ '@base/views/bill/bill-installment/reminderSuccess.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      name: 'LoginFaq',
      path: '/login/faq',
      component: LoginFaq,
      meta: {
        root: true,
      },
      children: [
        {
          path: 'no-sms-code',
          name: 'NoSmsCode',
          component: NoSmsCode,
        },
        {
          path: 'no-phone-number',
          name: 'NoPhoneNumber',
          component: NoPhoneNumber,
        },
      ],
    },
    {
      path: '/test/dsbridge',
      component: () => import('./views/test-dsbridge.vue'),
    },
    {
      path: '/entry',
      name: 'Entry',
      component: EntryPage,
      meta: {
        root: true,
        backTo: 'Home',
      },
    },
    //ios
    {
      path: '/entry-view',
      name: 'EntryView',
      component: EntryView,
      meta: {
        root: true,
        backTo: 'Home',
      },
    },
    {
      path: '/apply',
      redirect: '/apply/ktp',
      name: 'Apply',
      component: Apply,
      meta: {
        root: true,
        backTo: 'Home',
      },
      children: [
        {
          path: 'ktp',
          name: 'Ktp',
          component: Ktp,
          meta: {
            root: true,
            backTo: 'Home',
          },
        },
        {
          path: 'ktp-holding',
          name: 'KtpHolding',
          component: KtpHolding,
        },
        {
          path: 'first',
          name: 'First',
          component: First,
        },
        {
          path: 'second',
          name: 'Second',
          component: Second,
        },
        {
          path: 'native-ktp-entrance',
          name: 'FormEntry',
          component: NativeKTPEntrance,
        },
        {
          path: 'bank-info',
          name: 'BankInfo',
          component: BankInfo,
        },
        {
          //
          path: 'apply-supplement',
          name: 'ApplySupplement',
          component: ApplySupplement,
          meta: { newOKP: 'additional' },
        },
      ],
    },

    {
      path: '/update-info',
      name: 'PersonalInfoUpdate',
      component: UpdateInfo,
      children: [
        {
          name: 'UpdatePersonalInfo',
          path: 'personal-info',
          component: MyPersonalInfo,
        },
        {
          name: 'UpdateContactInfo',
          path: 'contact-info',
          component: MyContactInfo,
        },
        {
          name: 'UpdateResidentialInfo',
          path: 'residential-info',
          component: MyResidentialInfo,
        },
        {
          name: 'UpdateWorkInfo',
          path: 'work-info',
          component: MyWorkInfo,
        },
        {
          name: 'UpdateBankInfo',
          path: 'bank-info',
          component: MyBankInfo,
        },
      ],
    },

    // A/B Test: Linear_Application_Form B (basic info ，)
    {
      path: '/apply/b',
      redirect: '/apply/b/ktp-info',
      name: 'LinearApply',
      component: LinearApply,
      meta: {
        root: true,
        backTo: 'Home',
        // linearName: 'personalInfo',
      },
      children: [
        {
          path: 'ktp-info',
          name: 'LinearKtp',
          component: Ktp,
          meta: {
            root: true,
            // showStayPopup: true,
            backTo: 'Home',
          },
        },
        {
          path: 'ktp-holding',
          name: 'LinearKtpHolding',
          component: KtpHolding,
        },
        {
          path: 'liveness',
          name: 'LinearLivenessWithKtp',
          component: ModuleLivenessIntroduce,
        },
        {
          path: 'liveness-result',
          name: 'LinearLivenessResult',
          component: LinearLivenessResult,
        },
        {
          path: 'personal-info',
          name: 'LinearPersonalInfo',
          component: LinearPersonalInfo,
          meta: { linearName: 'personalInfo', backTo: 'LinearKtp', showStayPopup: true },
        },
        {
          path: 'work-info',
          name: 'LinearWorkInfo',
          component: LinearWorkInfo,
          meta: { linearName: 'workInfo' },
        },
        {
          path: 'contact-info',
          name: 'LinearContactInfo',
          component: LinearContactInfo,
          meta: { linearName: 'contactInfo' },
        },
        {
          path: 'bank-info',
          name: 'LinearBankInfo',
          component: LinearBankInfo,
          meta: { linearName: 'bankInfo' },
        },
        {
          path: 'linear-supplement',
          name: 'LinearSupplement',
          component: LinearSupplement,
          meta: { linearName: 'additional' },
        },
      ],
    },
    //  (***** growth )
    {
      name: 'ModuleApply',
      path: '/apply-step',
      component: ModuleApply,
      meta: {
        root: true,
        backTo: 'Home',
      },
      children: [
        {
          path: 'ktp',
          name: 'ModuleKtp',
          component: Ktp,
        },
        {
          path: 'ktp-holding',
          name: 'ModuleKtpHolding',
          component: KtpHolding,
        },
        {
          path: 'liveness-info',
          name: 'ModuleLivenessWithKtp',
          component: ModuleLivenessIntroduce,
        },
        {
          path: 'liveness-result',
          name: 'ModuleLivenessResult',
          component: LinearLivenessResult,
        },
        {
          path: 'bank-info',
          name: 'ModuleBankInfo',
          component: BankInfo,
        },
        {
          path: 'personal-info',
          name: 'ModulePersonalInfo',
          component: ModulePersonalInfo,
          meta: { showStayPopup: true },
        },
        {
          path: 'work-info',
          name: 'ModuleWorkInfo',
          component: ModuleWorkInfo,
        },
        {
          path: 'contact-info',
          name: 'ModuleContactInfo',
          component: ModuleContactInfo,
        },
        //
        {
          path: 'apply-supplement',
          name: 'ModuleApplySupplement',
          component: ApplySupplement,
          meta: { newOKP: 'additional' },
        },
      ],
    },
    {
      name: 'HasLimitPdlPreBorrow',
      path: '/apply/limit-pdl-pre-borrow',
      component: PdlPreBorrow,
    },
    {
      name: 'ApplyLoanPreBorrow',
      path: '/apply/pdl-pre-borrow',
      component: PdlPreBorrow,
      meta: {
        back: 'mandatoryBranch',
      },
    },
    {
      name: 'ApplyLoanPurpose',
      path: '/apply/pdl-pre-purpose',
      component: SelectLoan,
      meta: {
        back: 'ApplyLoanPurpose',
      },
    },
    {
      name: 'MandatoryBranch',
      path: '/apply/mandatory-branch',
      component: MandatoryBranch,
      meta: {
        back: 'mandatoryBranch',
      },
    },
    //
    // {
    //   path: '/supplement',
    //   name: 'Supplement',
    //   component: Supplement,
    // },
    {
      name: 'SupplementUpload',
      path: '/supplement/:type/upload',
      component: SupplementUpload,
    },
    {
      name: 'HRContactInfo',
      path: '/supplement/:type/hr',
      component: HRContactInfo,
    },
    {
      path: '/repayment-record',
      name: 'RepaymentRecord',
      component: RepaymentRecord,
    },
    {
      name: 'Resubmit',
      path: '/resubmit',
      component: Resubmit,
      children: [
        {
          name: 'ResubmitDownloadLatestApp',
          path: 'download-app',
          component: CommonDownloadApp,
          meta: {
            root: true,
            cantGoHack: true,
            newOKP: 'faceDetection',
          },
        },
        {
          name: 'ResubmitFaceDetectionStart',
          path: 'face-detection-start',
          component: ResubmitFaceDetectionStart,
          meta: {
            root: true,
            cantGoHack: true,
            newOKP: 'faceDetection',
            backTo: 'Home',
          },
        },
        {
          name: 'ResubmitFaceDetectionNext',
          path: 'face-detection-next',
          component: ResubmitFaceDetectionNext,
          meta: {
            root: true,
            cantGoHack: true,
            newOKP: 'faceDetection',
          },
        },
        {
          name: 'ResubmitKtp',
          path: 'ktp',
          component: ResubmitKtp,
          meta: {
            root: true,
            backTo: 'Home',
          },
        },
        {
          name: 'ResubmitKtpHolding',
          path: 'ktp-holding',
          component: ResubmitKtpHolding,
          meta: {
            root: true,
            backTo: 'Home',
          },
        },
        {
          name: 'ResubmitEmailVerificationPage',
          path: 'email',
          component: ResubmitEmailVerification,
          meta: {
            root: true,
            cantGoHack: true,
          },
        },
        {
          name: 'ResubmitBankInfo',
          path: 'bank-info',
          component: ResubmitBankInfo,
          meta: {
            root: true,
            backTo: 'Home',
          },
        },
        //
        {
          path: 'supplement',
          name: 'ResubmitSupplement',
          component: ApplySupplement,
          meta: {
            root: true,
            backTo: 'Home',
            newOKP: 'additional',
          },
        },
      ],
    },
    {
      name: 'ApplyLoan',
      path: '/home/apply-loan',
      component: ApplyLoan,
      meta: {
        // keepAlive: true,
        root: true,
        backTo: 'Home',
        showStayPopup: true,
      },
    },
    {
      name: 'BankList',
      path: '/home/bank-list',
      component: BankList,
    },
    {
      name: 'RejectedPage',
      path: '/home/rejected-page',
      component: RejectedPage,
      meta: {
        back: 'Home',
      },
    },
    {
      name: 'BankFaceDetectionStart',
      path: '/home/bank-face-detection-start',
      component: BankFaceDetectionStart,
      meta: {
        back: 'BankList',
      },
    },
    {
      name: 'BankDownloadLatestApp',
      path: '/home/download-app',
      component: CommonDownloadApp,
      meta: {
        back: 'BankList',
      },
    },
    {
      name: 'BankFaceDetectionNext',
      path: '/home/face-detection-next',
      component: BankFaceDetectionNext,
      meta: {
        back: 'BankList',
      },
    },
    {
      name: 'TCAgreement',
      path: '/home/tc-agreement/:id',
      component: TCAgreement,
    },
    {
      name: 'ViewPdf',
      path: '/home/apply-loan/ViewPdf',
      component: ViewPdf,
    },
    {
      name: 'Agreement',
      path: '/home/agreement/:type',
      component: AgreementIframe,
    },
    {
      name: 'AddLoanBankCard',
      path: '/home/add-card',
      component: AddLoanBankCard,
    },
    {
      name: 'VerifyNik',
      path: '/verify/nik',
      component: VerifyNik,
    },
    {
      name: 'HowToPayPage',
      path: '/bill/how-to-pay',
      component: BillHowToPay,
    },
    {
      name: 'ApplyExtension',
      path: '/bill/apply-extension',
      meta: {
        root: true,
        backTo: 'Bill',
      },
      component: ApplyExtension,
    },
    {
      name: 'PaymentMethod',
      path: '/bill/payment-method',
      component: PaymentMethod,
      meta: {
        root: true,
        backTo: 'Bill',
        back: 'PaymentMethod',
      },
    },
    {
      path: '/bill/statement',
      name: 'BillStatements',
      component: BillStatementsV2,
    },
    {
      path: '/bill/detail/:loanId',
      name: 'StatementDetails',
      component: StatementDetails,
    },
    {
      path: '/bill/apply-installment',
      name: 'ApplyBillInstallment',
      component: ApplyInstallmentPage,
    },
    {
      path: 'bill/apply-installment/faq',
      name: 'BillInstallmentFaq',
      component: BillInstallmentFaqPage,
    },
    {
      path: '/bill/installment-application',
      name: 'InstallmentApplicationOperation',
      component: BillApplication,
      // meta: {
      //   root: true,
      //   backTo: 'Bill',
      // },
    },
    {
      path: '/bill/installment-process',
      name: 'InstallmentApplicationProcessing',
      component: BillProcess,
      meta: {
        root: true,
        backTo: 'Bill',
      },
    },
    {
      path: '/bill/installment-schedule',
      name: 'ReminderScheduleList',
      component: BillSchedule,
      meta: {
        root: true,
        backTo: 'Bill',
      },
    },
    {
      path: '/bill/reminder-success',
      name: 'ReminderSuccess',
      component: ReminderSuccess,
    },
    {
      name: 'HelpCenter',
      path: '/me/help-center',
      component: HelpCenter,
      meta: {
        root: true,
        backTo: 'Me',
      },
    },
    {
      name: 'HelpCenterPaymentInstructions',
      path: '/me/help-center/how-to-pay',
      component: HelpCenterHowToPay,
    },
    {
      name: 'MyProfile',
      path: '/me/my-account/profile',
      component: MyProfile,
      meta: {
        // root: true,
        backTo: 'MyAccount',
      },
      children: [
        {
          name: 'MyPersonalInfo',
          path: 'personal-info',
          component: MyPersonalInfo,
        },
        {
          name: 'MyContactInfo',
          path: 'contact-info',
          component: MyContactInfo,
        },
        {
          name: 'MyResidentialInfo',
          path: 'residential-info',
          component: MyResidentialInfo,
        },
        {
          name: 'MyWorkInfo',
          path: 'work-info',
          component: MyWorkInfo,
        },
        {
          name: 'MyBankInfo',
          path: 'bank-info',
          component: MyBankInfo,
        },
      ],
    },
    {
      name: 'ChangeUserName',
      path: '/me/my-account/change-user-name',
      component: ChangeUserName,
    },
    {
      name: 'MyLinkAccount',
      path: '/me/my-account/link-account',
      component: MyLinkAccount,
      meta: {
        // root: true,
        backTo: 'MyAccount',
      },
    },
    {
      name: 'InvoicePage',
      path: '/me/invoice-page',
      component: InvoicePage,
    },
    {
      name: 'InvoicePdf',
      path: '/me/invoice-pdf',
      component: InvoicePdf,
    },
    //MyAccount
    {
      name: 'MyAccount',
      path: '/me/my-account',
      component: MyAccount,
      meta: {
        root: true,
      },
    },
    {
      name: 'MyLoans',
      path: '/me/my-loans',
      component: MyLoans,
    },
    {
      name: 'LoanDetails',
      path: '/me/loan-details/:id',
      component: LoanDetails,
    },
    {
      name: 'Risk',
      path: '/risk',
      component: () => import('./views/service-document/Risk.vue'),
      meta: {
        root: true,
        backTo: 'Me',
      },
    },
    {
      name: 'AboutUs',
      path: '/about-us',
      component: () => import('./views/service-document/AboutUs.vue'),
      meta: {
        root: true,
        backTo: 'Me',
      },
    },
    {
      name: 'LoanAgreement',
      path: '/loan-agreement',
      component: () => import('./views/service-document/LoanAgreement.vue'),
    },
    {
      name: 'OrderAgreement',
      path: '/:orderId/loan-agreement',
      component: () => import('./views/service-document/LoanAgreement.vue'),
    },
    //
    {
      path: '/push/temporary',
      component: TemporarySuccess,
      name: 'TemporarySuccess',
      meta: {
        root: true,
        backTo: 'Home',
      },
    },
    {
      path: '/push/vip-prod',
      component: VipProd,
      name: 'VipProd',
      meta: {
        root: true,
        backTo: 'Home',
      },
    },
    {
      path: '/score',
      name: 'Score',
      component: FiveStar,
    },
    {
      name: 'LinkAccountDetail',
      path: '/link-account/:type',
      component: LinkAccountDetail,
    },
    {
      name: 'VideoKyc',
      path: '/video/kyc',
      component: VideoKyc,
    },
    {
      name: 'PageList',
      path: '/pages',
      component: () => import('./views/test-utils/PageList.vue'),
    },
    {
      name: 'Utils',
      path: '/utils',
      component: () => import('./views/test-utils/Utils.vue'),
      meta: {
        root: true,
      },
    },
    {
      name: 'JumpOutApp',
      path: '/wps-insurance/entry-page',
      component: wpsEntryPage,
    },
    {
      name: 'FaceDetectionGuide',
      path: '/security/security-face-detection-start',
      component: BankFaceDetectionStart,
    },
    {
      name: 'FaceDetectionResultShow',
      path: '/security/security-face-detection-next',
      component: BankFaceDetectionNext,
    },
    ...formatRouteList(EffortlessLoanRoute),
    ...formatRouteList(JagoRoute),
    ...formatRouteList(MeRoute),
    ...formatRouteList(BaseCommonRoute),
    ...formatRouteList(AccountSecurityRoute),
    ...formatRouteList(DeleteAccountRoute),
    ...formatRouteList(DBRAlert),
    ...formatRouteList(VatRoute),
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && !to.meta?.pos) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next: Function) => {
  await fetchUserInfoFromLocal()
  next()
})

router.afterEach(async (to, from) => {
  console.log('afterEach time: ', new Date())
  removeLoading()
})

export default router
