import { ICouponItem } from '@ops/interface/coupon'
import ajax from '@base/lib/ajax'
import { IGetCouponListLoanReq } from '@ops/interface/coupon'
import { IABTestData } from '@base/lib/abTest'

export interface ICoupon {
  payAll: boolean
}

export interface IBindParams {
  couponId: string
  // earlySettlement: boolean
}

/**
 * coupon
 * @param params
 */
export const bindCoupon = (params: IBindParams) => {
  return ajax.post('/coupons/v4/change-bind', params)
}

/**
 * couponv3
 * @param params
 */
export const getCouponList = (params: ICoupon) => {
  return ajax.get<ICouponItem[]>('/coupons/v4/available-for-bills', { params })
}

/**
 * coupon apply loan
 * @param params
 */
export const getCouponListLoan = (params: { productId: string; amount?: number; tenor?: number }) => {
  return ajax.get('/coupons/v4/available-for-loan', { params })
}

/** code  coupon */
export const redeemCouponByCode = (code: string) => {
  return ajax.post(`/o/redeem-code/redeem?code=${code}`)
}

/**
 *  coupon  loan intention(pre borrow) / pre borrow(has limit pre borrow)
 * @param params
 */
export const getCouponListLoans = (params: IGetCouponListLoanReq) => {
  return ajax.get<ICouponItem[]>('/coupons/v4/available-for-loans', { params })
}

export const getCouponRetentionInfo = () => {
  return ajax.get<{
    code: string
    data: ICouponItem
    message: string
  }>('/coupons/v4/retention')
}

export const claimCouponRetention = (params: IABTestData) => {
  return ajax.post('/coupons/v4/retention/issue', params)
}
