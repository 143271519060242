export const downLoadURI = 'https://play.google.com/store/apps/details?id=com.gojek.app'
export const AD_CHANNEL = 'advertising-channel'
export const UTM_SOURCE = 'utm_source'
export const AGENCY_TOKEN = 'agency_token'
import { OtherChannel } from '@base/enum/channel'
import { IUserModuleItem } from '@base/interface/user'

export const LocationPermissionList = [
  'android.permission.ACCESS_FINE_LOCATION',
  'android.permission.ACCESS_COARSE_LOCATION',
]

export const DownLoadAppURL = 'https://play.google.com/store/apps/details?id=com.kreditpintar'
export const DownLoadIosAppURL = 'https://apps.apple.com/id/app/kredit-pintar-pinjaman-online/id6444848617'

export const CHANNEL_URL = 'channelUrl'

/** List of third-party channels */
export const OtherChannelList = [
  OtherChannel.LINKAJA,
  OtherChannel.OTTOH5,
  OtherChannel.MITRA,
  OtherChannel.MYIM3,
  OtherChannel.BIMA3,
  OtherChannel.MOXA,
]

export const FreeLoginChannelList = [OtherChannel.MOXA, OtherChannel.MYIM3, OtherChannel.BIMA3, OtherChannel.MITRA]

export const DownLoadAppAPK = (downloadChannel: string) => {
  return `https://jkt-apk-download.oss-ap-southeast-5.aliyuncs.com/middle/channel_${
    downloadChannel ? downloadChannel : 'adv'
  }.apk`
}

export const CommonCDNPath = 'https://static.kreditpintar.net/k8s-kp-website'

/**
 * API ， sentry
 */
export const ApiWhiteList = [
  '/user/payment-info/v2',
  '/o/redeem-code/redeem',
  '/o/vgs/service-product/inquire-postpaid-bill',
  '/o/vgs/service-product/available',
  '/verify-phone/send-otp',
  '/user/work-info/v2',
  '/user/contact-info/v2',
  '/user/ktp-info/v3',
  '/user/personal-and-residential/v3',
]
/**
 * Sentry not uplaod，code list: There are logs in the backend of these bank card verification codes and they will no longer be reported
 */
export const CodeWhiteList = ['BANK_VALID_PENDING', 'MISMATCH_ACCOUNT', 'INVALID_ACCOUNT']

/**
 *  List of modules for user application and profile
 */
export const UserInfoModuleList: IUserModuleItem[] = [
  {
    icon: 'steplist_face',
    name: 'faceIdentification',
    isCompleted: false,
    completion: ['faceIdentificationInfoCompleteStatus'],
    event: 'ktp',
    isShow: false,
  },
  {
    icon: 'steplist_personal',
    name: 'personalInfo',
    isCompleted: false,
    completion: ['basicInfoCompleteStatus', 'personalInformationCompleted'],
    event: 'personal_info',
    isShow: false,
  },
  {
    icon: 'steplist_contact',
    name: 'contactInfo',
    isCompleted: false,
    completion: ['contactInfoCompleteStatus', 'contactInformationCompleted'],
    event: 'contact_info',
    isShow: false,
  },
  {
    icon: 'steplist_residential',
    name: 'residentialInfo',
    isCompleted: false,
    completion: ['residentialInfoCompleteStatus', 'residentialInformationCompleted'],
    event: 'residential_info',
    isShow: false,
  },
  {
    icon: 'steplist_work',
    name: 'workInfo',
    isCompleted: false,
    completion: ['workInfoCompleteStatus', 'workInformationCompleted'],
    event: 'work_info',
    isShow: false,
  },
  {
    icon: 'steplist_bank',
    isCompleted: false,
    completion: ['bankAccountInfoCompleteStatus', 'bankAccountInfoCompleted'],
    name: 'bankInfo',
    event: 'bank_info',
    isShow: false,
  },
  {
    icon: 'steplist_additional',
    isCompleted: false,
    name: 'requiredAdditionalInfo',
    routeName: 'ModuleApplySupplement',
    event: 'additional_info',
    isShow: false,
  },
]

export const invalidNames = [
  'ibu',
  'mamah',
  'ayah',
  'almarhum',
  'orang tua',
  'ibuku',
  'mama',
  'mamaku',
  'mum',
  'mother',
  'mami',
  'mamih',
  'mommy',
  'bunda',
  'mom',
  'orangtua',
  'papih',
  'almh',
  'bundaku',
  'ibunda',
  'ibundaku',
  'umiku',
  'emak',
  'mamak',
  'mbok',
  'almarhumah',
  'bapak',
  'papa',
  'dad',
  'daddy',
  'papi',
  'papah',
  'father',
  'bokap',
  'nyokap',
  'pipi',
  'pipih',
  'mimi',
  'mimih',
  'papaku',
  'bapa',
  'abah',
  'babeh',
  'baba',
  'babah',
  'babe',
  'ema',
  'enya',
  'enyak',
  'maknyak',
  'kesayangan',
  'ukhti',
  'ayahanda',
  'ummi',
  'umi',
  'bapaku',
  'mumuy',
  'mummu',
  'umamah',
]

export const ERROR_MESSAGE = ['COUPON_CLAIM_ALREADY_EXIST']
