



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import '@/lib/api-components'

interface IOption {
  value: string | number | boolean
  text: string | number
  [k: string]: string | number | boolean
}

@Component({
  i18n: {
    messages: {
      'en-us': {
        workSince: 'Work since',
        cancel: 'Cancel',
        next: 'Yes',
      },
      id: {
        workSince: 'Bekerja sejak',
        cancel: 'Batal',
        next: 'Ya',
      },
    },
  },
})
export default class Selector extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  options!: IOption[]

  @Prop({
    type: String,
    default: 'Picker',
  })
  type!: string
  @Prop({
    type: [String, Number, Array],
    default: '',
  })
  value!: string | number | []
  @Prop({ type: [String, Number], default: '' }) title!: string | number
  @Prop({ type: [String, Number], default: '' }) placeholder!: string | number
  @Prop({ type: [String, Number], default: '' }) error!: string | number
  @Prop({ type: Boolean, default: false }) autoRemove!: boolean
  @Prop({ type: String, default: '' }) innerTitle!: string
  @Prop({ type: String, default: '' }) kind!: string
  @Prop({ type: String, default: 'normal' }) arrowType?: string

  private innerValue: string | number | [] = ''
  private picker!: any

  @Watch('value', { immediate: true })
  valueChange(val: string | number | []) {
    this.innerValue = val
  }
  @Watch('options')
  optionsChange(val: IOption[]) {
    if (this.type !== 'date' && this.type !== 'birthDate' && this.picker && val.length > 0) {
      this.picker.$updateProps({
        data: val,
      })
      //
      this.$nextTick(() => {
        this.picker.$children[0].$children[1].reflowScroller()
      })
    }
  }

  get innerTxt() {
    if (this.type === 'birthDate') return this.value
    if (this.type === 'date') return this.value
    return this.type !== 'date' && this.options.filter((item) => item.value === this.innerValue)[0]?.text
  }

  clickHandler() {
    this.$refs.mdInput && (this.$refs.mdInput as any).blur()
    this.showPicker()
    window.vue.isShowPopUp = true

    this.$emit('click')
  }

  selectHandle(option: IOption) {
    this.$emit('input', option.value)
  }

  hideHandle() {
    window.vue.isShowPopUp = false

    this.picker.$updateProps({
      value: false,
    })
    this.$emit('hide')
  }

  confirmHandler(json: any) {
    if (json.length === 3) {
      this.$emit(
        'input',
        `${json[2]?.value < 10 ? '0' + json[2]?.value : json[2]?.value}-${
          json[1]?.value < 10 ? '0' + json[1]?.value : json[1]?.value
        }-${json[0]?.value}`,
      )
    } else {
      this.$emit('input', `${json[0]?.value}-${json[1]?.value}`)
    }
    this.hideHandle()
  }

  showPicker() {
    this.$nextTick(() => {
      const value = this.value ? this.value?.toString()?.split('-') : ''
      if (this.picker && this.type === 'Picker') {
        this.picker.$updateProps({
          title: this.title,
        })
      }
      if (!this.picker && this.type === 'birthDate') {
        this.picker = this.$createMdDatePicker({
          $props: {
            value: true,
            type: 'custom',
            title: this.title,
            'today-text': '&(today)',
            'custom-types': ['yyyy', 'MM', 'dd'],
            'unit-text': ['', '', ''],
            'default-date': this.value ? new Date(`${value[2]}-${value[1]}-${value[0]}`) : new Date(),
            'min-date': new Date('1900/1/1'),
            'max-date': new Date(),
            'cancel-text': this.$t('cancel'),
            'ok-text': this.$t('next'),
          },
          $events: {
            confirm: this.confirmHandler,
            hide: this.hideHandle,
          },
        })
      }
      if (!this.picker && this.type !== 'date') {
        this.picker = this.$createMdSelector({
          $props: {
            value: true,
            data: this.options,
            title: this.title,
            'default-value': this.value,
            'max-height': '50vh',
          },
          $events: {
            choose: this.selectHandle,
            hide: this.hideHandle,
          },
        })
      }
      if (!this.picker && this.type === 'date') {
        this.picker = this.$createMdDatePicker({
          $props: {
            value: true,
            type: 'custom',
            title: this.$t('workSince'),
            'today-text': '&(today)',
            'custom-types': ['yyyy', 'MM'],
            'unit-text': ['', '', ''],
            'default-date': new Date(),
            'max-date': new Date(),
            'cancel-text': this.$t('cancel'),
            'ok-text': this.$t('next'),
          },
          $events: {
            confirm: this.confirmHandler,
            hide: this.hideHandle,
          },
        })
      }
      if (this.picker) {
        this.picker.$updateProps({
          value: true,
        })
      }
      if (this.autoRemove) {
        this.picker.$on('hide', (json: any) => {
          this.picker.remove()
          this.picker = null
        })
      }
    })
  }
}
